import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/index";
Vue.use(Vuex);
export const QueryString = (object) => {
  let routeParams = "";
  if (object !== null) {
    routeParams = Object.keys(object)
      .map((key) => key + "=" + object[key])
      .join("&");
  }
  return routeParams;
};
const url = "https://lt-server.lisa-und-tommy.de";
 // const url = "http://localhost:1234";

export default new Vuex.Store({
  state: {
    code: {},
    visit: null,
    authed: null,
    SecData: {},
    userData: null,
    error: null,
    newCode: null,
    allCodes: {},
    loading: false,
  },
  mutations: {
    SET_CODE(state, code) {
      state.code = code;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_USERDATA(state, data) {
      state.userData = data;
    },
    SET_RUECKMELDUNG(state, data) {
      state.data = data;
    },
    SET_VISIT_FIRST(state, data) {
      state.visit = data;
    },
    SET_AUTHED(state, data) {
      state.authed = data;
    },
    SET_DATA(state, data) {
      state.SecData = data;
    },
    SET_ALL_CODES(state, data) {
      state.allCodes = data;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    SET_NEWCODE(state, newCode) {
      state.newCode = newCode;

      state.allCodes.push(newCode);
    },
    REMOVE_CODE(state, index) {
      state.allCodes.splice(index, 1);
    },
    UPDATE_CODE(state, data, index) {
      state.allCodes.splice(index, 1);
      state.allCodes.push(data)
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async checkCode({ commit }, { code = null, route = null }) {
      commit("CLEAR_ERROR");
      commit("SET_LOADING", true);

      console.log(QueryString({ code }));
      const response = await axios({
        url: url + "/" + QueryString({ code, command: "checkCode" }),
        method: "get",
      });
      //console.log(response);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === true
      ) {
        if (response.data.first === true) {
          commit("SET_VISIT_FIRST", response.data.first);
        }
        commit("SET_CODE", response.data.code);
        if (route === null) {
          router.push({ path: `/${response.data.code._code}` });
        }
      } else {
        router.push({ path: "/" });
        commit("SET_ERROR", "CODE IST NICHT GÜLTIG!");
      }
      commit("SET_LOADING", false) 
    },

    async secAuth({ commit }, { auth = null }) {
      commit("CLEAR_ERROR");
      commit("SET_ERROR", "Falsche Email-Adresse");
      if (auth === true) {
        commit("SET_AUTHED", auth);
      } else {
        commit("SET_ERROR", "Falsche Email-Adresse");
      }
    },

    async secData({ commit }, { code = null }) {
      const response = await axios({
        url: url + "/" + QueryString({ code, command: "getData" }),
        method: "get",
      });
      //console.log("responseData:", response.data.results);
      if (response) {
        commit("SET_DATA", response.data.results);
      }
    },

    async setRueckmeldung({ commit }, { sendData = null }) {
      console.log("ruckmeldung", sendData);

      const response = await axios({
        url:
          url + "/" + QueryString({ ...sendData, command: "setRueckmeldung" }),
        method: "post",
      });

      console.log("response", response);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === true
      ) {
        commit("SET_RUECKMELDUNG", response.data.sendData);
      }
    },

    async setRueckmeldungSelf({ commit }, { sendData = null, editedIndex = null }) {
      console.log("ruckmeldung", sendData);
      console.log("ruckmeldung", editedIndex);

      const response = await axios({
        url:
          url + "/" + QueryString({ ...sendData, command: "setRueckmeldung" }),
        method: "post",
      });

      console.log("response", response);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === true
      ) {
        commit("SET_RUECKMELDUNG", response.data.sendData);
        console.log(this.getters.getAllCodes);
        console.log("sendData",sendData);
        commit("UPDATE_CODE", response.data.sendData, editedIndex);
      }
    },

    async updateRueckmeldung({ commit }, { sendData = null }) {
      console.log("ruckmeldung", sendData);

      const response = await axios({
        url:
          url +
          "/" +
          QueryString({ ...sendData, command: "updateRueckmeldung" }),
        method: "post",
      });

      console.log(response);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === true
      ) {
        commit("SET_RUECKMELDUNG", response.data.sendData);
      }
    },

    async setLogin({ commit }, { userData = null }) {
      //console.log("userData:", userData);
      commit("CLEAR_ERROR");

      const response = await axios({
        url: url + "/" + QueryString({ ...userData, command: "loginUser" }),
        methos: "get",
      });

      //console.log(response);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === true
      ) {
        commit("SET_USERDATA", response.data.user);
        //console.log("userData", response.data.user, this.getters.getUser);
      } else if (!response.data.status) {
        commit("SET_ERROR", "FEHLER IM LOGIN");
      }
    },

    clearError({ commit }) {
      commit("CLEAR_ERROR");
    },

    async newCode({ commit }, { name = null }) {
      var goForIt = false;

      while (goForIt === false) {
        var code = Math.floor(Math.random() * (999999 - 222222 + 1) + 222222);
        var usedc = false;
        console.log(this.getters.allCodes);
        this.state.allCodes.forEach((element) => {
          if (element._code === code) {
            usedc = true;
            console.log("code already in use!");
          }
        });

        if (usedc === false) {
          console.log(QueryString({ code }));
          const response = await axios({
            url: url + "/" + QueryString({ code, command: "checkCode" }),
            method: "get",
          });

          console.log(response);
          if (response && response.data.status === false) {
            const response1 = await axios({
              url: url + "/" + QueryString({ name, code, command: "setCode" }),
              method: "post",
            });

            console.log("response1", response1);
            if (response1) {
              goForIt = true;
              commit("SET_NEWCODE", response1.data);
              console.log("set new code state");
            }
          }
        }
      }
    },

    async deleteCode({ commit }, { code = null, index = null }) {
      console.log(code);
      console.log(index);

      const response = await axios({
        url: url + "/" + QueryString({ code, command: "deleteCode" }),
        method: "post",
      });

      console.log("DeleteResponse", response);
      if (response) {
        commit("REMOVE_CODE", index);
        console.log("deleted Code");
      }
    },

    async allCodes({ commit }) {
      const response = await axios({
        url: url + "/" + QueryString({ command: "allCodes" }),
        method: "get",
      });

      if (response) {
        console.log("allCodes", response.data);
        commit("SET_ALL_CODES", response.data);
      }
    },
  },

  getters: {
    getCode(state) {
      return state.code;
    },
    getUser(state) {
      return state.userData;
    },
    getData(state) {
      return state.SecData;
    },
    getFirstVisit(state) {
      return state.visit;
    },
    getAuthed(state) {
      return state.authed;
    },
    getError(state) {
      return state.error;
    },
    getNewCode(state) {
      return state.newCode;
    },
    getAllCodes(state) {
      return state.allCodes;
    },
    getLoading(state) {
      return state.loading;
    },
  },
});
