<template>
  <div>
    <v-btn rounded class="mr-3" @click.stop="editDialog = true">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-dialog width="600px" persistent v-model="editDialog">
      <v-card>
        <v-container>
          <v-toolbar text rounded elevation="0" color="primary" dark>
            <v-toolbar-title>Neuer Code wurde angelegt:</v-toolbar-title>
          </v-toolbar>

          <v-layout row wrap class="mt-5">
            <v-flex xs12>
              <v-card-text>
                <p>{{ code.name }}: {{ code.code }}</p>
              </v-card-text>
            </v-flex>
          </v-layout>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="warning"
              class="darken-1"
              @click="editDialog = false"
              >Abbrechen</v-btn
            >
            <v-btn color="success" class="darken-1" @click="onSaveChanges"
              >OK</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["code"],
  data() {
    return {
      editDialog: false,
      newCode: {
        code: this.code.code,
        name: this.code.name,
      },
    };
  },
  methods: {
    onSaveChanges() {
      this.editDialog = false;
    },
  },
};
</script>
