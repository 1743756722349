<template>
  <v-app>
    <component :is="theme">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const Theme = () =>
  import(/* webpackChunkName: "Theme" */ "./components/Theme");
const firstTheme = () =>
  import(/* webpackChunkName: "firstTheme" */ "./components/ThemeFirst");
const ThemePage = () =>
  import(/* webpackChunkName: "ThemePage" */ "./components/ThemePage");

export default {
  name: "App",

  components: {
    Theme,
    firstTheme,
    ThemePage,
  },
  computed: {
    ...mapState({
      overlayActive: (state) => state.ui.overlay,
    }),
    theme() {
      return this.$route.meta.layout;
    },
  },
  data: () => ({
    //
  }),
};
</script>
