import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
// import FirstVisit from "../views/FirstVisit.vue";
// import SecondVisit from "../views/SecondVisit.vue";
// import LogIn from "../views/LogIn.vue";
// import ShowAnm from "../views/showAnm.vue";
// import newCode from "../views/newCode.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "FirstVisit",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    meta: { layout: "firstTheme" },
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () =>
      import(/* webpackChunkName: "Impressum" */ "../views/Impressum.vue"),
    meta: { layout: "ThemePage" },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () =>
      import(/* webpackChunkName: "Datenschutz" */ "../views/Datenschutz.vue"),
    meta: { layout: "ThemePage" },
  },
  {
    path: "/admin",
    name: "LogIn",
    // component: LogIn,
    component: () =>
      import(/* webpackChunkName: "LogIn" */ "../views/LogIn.vue"),
    meta: { layout: "ThemePage" },
  },
  {
    path: "/:code",
    name: "FirstVisit",
    // component: FirstVisit,
    component: () =>
      import(/* webpackChunkName: "FirstVisit" */ "../views/FirstVisit.vue"),
    meta: { layout: "Theme" },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BABEL_ENV,
});

export default router;
